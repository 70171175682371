import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"


import { FormattedMessage, injectIntl} from "gatsby-plugin-intl"

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';


const query = graphql`
  query {
   file(relativePath: { eq: "empfang.png" }) {
      childImageSharp {
        fluid (maxWidth: 700, quality: 80, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const ImageEmpfang = () => (
  
     /* <Img  fluid={useStaticQuery(query).file.childImageSharp.fluid} alt="Bild des Empfangbereichs"   style={{ margin: '0.5rem', maxHeight: 'calc(60vh * 0.6)' }}  imgStyle={{ objectFit: 'cover' }}/>  */
  <Img className="tw-h-56 tw-w-full tw-object-cover"  fluid={useStaticQuery(query).file.childImageSharp.fluid} alt="Bild des Empfangbereichs" /> 
);




//export default ({ data }) => {
const IndexPage = ({ intl, data }) => {
  return (
  <>
    <Layout>

    <SEO lang={intl.locale} 
            title={intl.formatMessage({ id: "Pages.Index.title",defaultMessage:"Index" })} 
            description={intl.formatMessage({ id: "Pages.Index.description",defaultMessage:"Kontaktdaten , u.a. Anschrift, Telefon, E-mail und Social Media." })}     
        />

    <div className="tw-py-10 tw-bg-gradient-to-r tw-from-green-300 tw-to-green-400" > 
      <h2 className="tw-text-xl tw-xl:tw-text-4xl tw-font-bold tw-mb-4 tw-text-center tw-text-white">
        Fachkompetenz für Ihre Haut!
      </h2>
       <div className="tw-relative" >
          <ImageEmpfang></ImageEmpfang>

          <button className="tw-absolute tw-top-0  tw-bg-white tw-font-bold tw-rounded-full tw-m-12 tw-py-4 tw-px-8 tw-shadow-lg tw-uppercase tw-tracking-wider">
              <a href="tel:0421- 520 79 575" className="tw-text-xs tw-xl:tw-text-2xl tw.text-bold tw-text-grey-800 ">
                Termin nach Vereinarung <br/>0421- 520 79 575        
              </a>
          </button>
      </div>

      <div className="tw-container tw-mx-auto tw-px-6">

        <h3 className="tw-text-xl tw-xl:tw-text-2xl tw-mt-4 tw-text-center tw-text-white">
          HAUT- und LABORARZT
        </h3>
        <p className="tw-text-xs tw-xl:tw-text-2xl tw-mt-4 tw-text-center tw-text-white">
        (Allergologie, Reise- und Umweltmedizin, Untersuchungen nach Infektions- und Gendiagnostikgesetz)
        </p>


      </div>
     </div>   
    <container  className="tw-block tw-w-full tw-mx-4"> 
    <h1 className="tw-text-gray-800 tw-mt-12 tw-mb-4 -tw-ml-1 tw-text-xl tw-font-extrabold tw-tracking-wider"> Willkommen! </h1>

    <h2 className="tw-text-gray-800 tw-mt-12 tw-mb-4 -tw-ml-1 tw-text-xl tw-font-bold tw-tracking-wide">
     Prof. Dr. med. Peter Michael Kövary, <br /> Dr. med. Anna Maria Müller (Assistenzärztin)</h2>
    
    <p className="tw-text-gray-800 tw-leading-relaxed tw-font-light tw-mt-2 tw-mb-4">
    Auf diesen Seiten informieren wir Sie zu unseren Angeboten und unseren diagnostischen Möglichkeiten. 
    </p>

    <p className="tw-text-gray-800 tw-leading-relaxed tw-font-light tw-mt-2 tw-mb-4">
    Wir bieten Ihnen als unseren Patienten selbstverständlich eine umfassende Beratung, Diagnostik und Therapie über die gesamte Bandbreite der Hauterkrankungen. Von der allgemeinen Dermatologie und Allergologie über die Hautkrebsvorsorge, Aknebehandlung bis hin zu modernster Lasermedizin und photodynamischer Therapie zum Beispiel bei der Hautkrebsbehandlung.
    </p>

    <p className="tw-text-gray-800 tw-leading-relaxed tw-font-light tw-mt-2 tw-mb-4">
    Unser Leistungsspektrum umfasst unter anderem:
    <ul className="tw-list-disc tw-m-4">
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Aknebehandlung                                                  </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Allgemeine Dermatologie                                         </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Allergologie                                                    </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Ambulante Operationen                                           </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Haarerkrankungen                                                </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Hautkrebs Vor- und Nachsorge                                    </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Hyperhidrosis (krankhaftes Schwitzen)                           </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Lasermedizin                                                    </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Photodynamische Therapie(PDT)                                   </li>
        <li className="tw-text-gray-800 tw-font-light tw-my-1">  Venenerkrankungen (Behandlung der Besenreiser)                  </li>
    </ul>
    </p>
    <p className="tw-text-gray-800 tw-leading-relaxed tw-font-light tw-mt-2 tw-mb-4">
      Überzeugen Sie sich von unserer langjährigen Erfahrung, unserem Fachwissen und unseren modernen Behandlungsmethoden im dermatologischen und allergologischen Bereich.
    </p>
    <p className="tw-text-gray-800 tw-leading-relaxed tw-font-light tw-mt-2 tw-mb-4">
    Herzliche Grüße, Ihr Praxisteam
    </p>
   
    </container> 

 
   
    

  </Layout>
  </>)
}
export default injectIntl(IndexPage)